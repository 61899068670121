import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CABINET_TASKS_NEW_ROUTE, CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto, OrderStatusType } from 'src/shared/config/types'
import { AdminAPI, CommonMessageResponse, HttpResponse, OrderManagementDto } from 'src/shared/api'
import { Task } from 'src/shared/ui/Task'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CabinetTasks.module.scss'

export const CabinetTasks: FC = () => {
  const navigate = useNavigate()
  const handleAddTaskClick = useCallback(() => navigate(CABINET_TASKS_NEW_ROUTE), [navigate])
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])

  const queryClient = useQueryClient()

  const { data: ordersData } = useQuery({
    queryKey: ['orders'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.ordersControllerFindAll({ page: 1, take: 200 }),
  })

  const changeOrderStatusMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { id: number, variables: OrderManagementDto }
  >({
    mutationFn: ({ id, variables }: { id: number, variables: OrderManagementDto }) => AdminAPI.api.ordersControllerChangeOrderStatus(id, variables),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders'] })
  })

  const orders = useMemo(() => (ordersData?.data?.data ?? []) as CustomerOrderListResponseDto[], [ordersData])

  const handleStatusChange = useCallback((id: number, status: OrderStatusType) => {
    changeOrderStatusMutation.mutate({ id, variables: { status } })
  }, [changeOrderStatusMutation])

  return (
    <CabinetPageLayout>
      <Flex justifyContent="space-between" alignItems="center">
        <h1 className={styles.Title}>Мои задания</h1>
        <CabinetGhostButton onClick={handleAddTaskClick} icon="plus">Добавить задание</CabinetGhostButton>
      </Flex>
      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => (
          <Task
            key={item.id}
            task={item}
            onClick={openTask}
            onStatusChange={handleStatusChange}
          />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
