import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import {
  CABINET_TASKS_ROUTE,
  CABINET_PRODUCTS_ROUTE,
  CABINET_PROFILE_ROUTE,
  CABINET_TOPICS_ROUTE,
  CABINET_MARKETPLACES_ROUTE,
  CABINET_USERS_ROUTE,
  CABINET_NOTIFICATIONS,
  CABINET_SETTINGS,
} from 'src/shared/config/consts'
import { Logo } from '../Logo/Logo'
import { TelegramLink } from '../TelegramLink/TelegramLink'
import { IconButton } from '../IconButton/IconButton'
import { useUserInfo } from 'src/shared/model/useUser'
import { RoleType } from 'src/shared/config/types'
import { Avatar } from 'src/shared/ui/Avatar'
import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { plural } from 'src/shared/lib/plural'

import styles from './Header.module.scss'

export const Header: FC = () => {
  const { user } = useUserInfo()
  const navigate = useNavigate()

  const handleProfileClick = useCallback(() => navigate(CABINET_PROFILE_ROUTE), [navigate])
  const handleNotificationsClick = useCallback(() => navigate(CABINET_NOTIFICATIONS), [navigate])
  const handleSettingsClick = useCallback(() => navigate(CABINET_SETTINGS), [navigate])

  const profile = useCustomerProfile()

  return (
    <div className={styles.Header}>
      <Logo />
      <div className={styles.Navigation}>
        {user?.role === RoleType.CUSTOMER && (
          <>
            <Link className={styles.NavigationItem} to={CABINET_PRODUCTS_ROUTE}>
              Мои товары
            </Link>
            <Link className={styles.NavigationItem} to={CABINET_TASKS_ROUTE}>
              Мои задания
            </Link>
            {profile && (
              <a className={styles.NavigationItem}>
                {`Мой баланс: ${profile.total || 0} ${plural(['интеграция', 'интеграции', 'интеграций'], profile.total || 0)}`}
              </a>
            )}
          </>
        )}
        {user?.role === RoleType.BLOGER && (
          <>
            <Link className={styles.NavigationItem} to={CABINET_TASKS_ROUTE}>
              Мои задания
            </Link>
          </>
        )}
        {user?.role === RoleType.SUPER_ADMIN && (
          <>
            <Link className={styles.NavigationItem} to={CABINET_USERS_ROUTE}>
              Анкеты
            </Link>
            <Link className={styles.NavigationItem} to={CABINET_PRODUCTS_ROUTE}>
              Товары
            </Link>
            <Link className={styles.NavigationItem} to={CABINET_TASKS_ROUTE}>
              Задания
            </Link>
            <Link className={styles.NavigationItem} to={CABINET_TOPICS_ROUTE}>
              Топики товаров
            </Link>
            <Link className={styles.NavigationItem} to={CABINET_MARKETPLACES_ROUTE}>
              Маркетплейсы
            </Link>
          </>
        )}
      </div>
      <div className={styles.Menu}>
        <TelegramLink />
        <IconButton icon="settings" onClick={handleSettingsClick} />
        <IconButton icon="bell" onClick={handleNotificationsClick} />
      </div>
      {user && (
        <Avatar
          className={styles.Avatar}
          avatar={user.avatar}
          firstName={user.firstName}
          onClick={handleProfileClick}
        />
      )}
    </div>
  )
}
