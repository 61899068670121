import React, { FC, useCallback, useMemo } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { getPasswordAgainRules } from 'src/shared/lib/getPasswordAgainRules'
import { passwordRules } from 'src/shared/lib/passwordRules'
import { AdminAPI, ChangePasswordDto, CommonMessageResponse, HttpResponse } from 'src/shared/api'
import { useMutation } from '@tanstack/react-query'

import styles from './CabinetChangePassword.module.scss'

interface CabinetChangePasswordFields {
  oldPassword: string
  newPassword: string
  newPasswordAgain: string
}

export const CabinetChangePassword: FC = () => {
  const methods = useForm<CabinetChangePasswordFields>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordAgain: '',
    },
  })
  const { handleSubmit, watch, reset, setError } = methods
  const values = watch()

  const changePasswordMutation = useMutation<
    HttpResponse<CommonMessageResponse>,
    HttpResponse<CommonMessageResponse>,
    ChangePasswordDto
  >({
    mutationFn: AdminAPI.api.authControllerChangePassword,
  })
  const isLoading = changePasswordMutation.isPending

  const onSubmit = useCallback(
    async (data: CabinetChangePasswordFields) => {
      const changePasswordDto: ChangePasswordDto = {
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      }

      changePasswordMutation.mutate(changePasswordDto, {
        onSuccess: () => {
          reset({
            oldPassword: '',
            newPassword: '',
            newPasswordAgain: '',
          })
        },
        onError: (response) => {
          if (typeof response.error === 'object' && response.error !== null) {
            setError('newPasswordAgain', response.error)
          }
        },
      })
    },
    [changePasswordMutation],
  )

  const passwordAgainRules = useMemo(() => getPasswordAgainRules(values.newPassword), [values.newPassword])

  const isAuthButtonDisabled = useMemo(
    () => !(values.oldPassword && values.newPassword && values.newPasswordAgain) || isLoading,
    [values],
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
        <div className={styles.FormInputs}>
          <TextInputFormField
            id="oldPassword"
            name="oldPassword"
            label="Введите текущий пароль"
            placeholder="Пароль"
            type="password"
            kind="cabinet"
            rules={passwordRules}
          />
          <TextInputFormField
            id="newPassword"
            name="newPassword"
            label="Придумайте новый пароль"
            placeholder="Пароль"
            type="password"
            kind="cabinet"
            rules={passwordRules}
          />
          <TextInputFormField
            id="newPasswordAgain"
            name="newPasswordAgain"
            label="Повторите пароль"
            placeholder="Пароль"
            type="password"
            kind="cabinet"
            rules={passwordAgainRules}
          />
        </div>

        <CabinetButton className={styles.CabinetButton} disabled={isAuthButtonDisabled}>
          Сохранить
        </CabinetButton>
      </form>
    </FormProvider>
  )
}
