import React, { FC, useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { ProductForm as BaseProductForm, ProductFormFields, useProductForm } from 'src/widgets/ProductForm'
import { Flex } from 'src/shared/ui/Flex'
import { Avatar } from 'src/shared/ui/Avatar'
import { Field } from 'src/shared/ui/Field'
import { useUpload } from 'src/shared/model/useUpload'
import { AdminAPI, CommonMessageResponse, HttpResponse, ProductResponseDto, UpdateProductDto } from 'src/shared/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { ProductStatusType } from 'src/shared/config/types'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'

import styles from './ProductForm.module.scss'

interface ProductFormPropsType {
  productId: number
  product: ProductResponseDto
}

export const ProductForm: FC<ProductFormPropsType> = ({ productId, product }) => {
  const navigate = useNavigate()
  const { methods, isInputsInvalid } = useProductForm(product)
  const { handleSubmit } = methods

  const queryClient = useQueryClient()

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const { mutateAsync: updateProductMutation, isPending: isUpdateProductLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateProductDto
  >({
    mutationFn: async (data: UpdateProductDto) => AdminAPI.api.managementControllerSetProductById(productId, data),
  })

  const { mutateAsync: productVerificationMutation, isPending: isProductVerificationLoading } = useMutation({
    mutationFn: AdminAPI.api.managementControllerVerificationProduct,
  })

  const isLoading = isUpdateProductLoading || isProductVerificationLoading || isUploading

  const isButtonDisabled = isInputsInvalid || isLoading

  const updateProduct = useCallback(
    async (data: ProductFormFields) => {
      try {
        let photoUrl: string
        if (typeof data.photo === 'string') {
          photoUrl = data.photo
        } else {
          setIsUploading(true)
          photoUrl = await upload(data.photo)
          setIsUploading(false)
        }

        const updateProductDto: UpdateProductDto = {
          topics: data.topics.filter((item) => item.topicId).map((item) => ({ topicId: Number(item.topicId) })),
          marketplaceUrl: data.marketplaceUrl,
          marketplaceId: Number(data.marketplaceId),
          title: data.title,
          article: data.article,
          price: Number(data.price),
          photoUrl,
        }

        await updateProductMutation(updateProductDto, {
          onError: (error) => {
            console.error(error.error)
          },
        })
      } catch (e) {
        console.error(e)
        setIsUploading(false)
      }
    },
    [updateProductMutation, product],
  )

  const onSubmit = useCallback(
    async (data: ProductFormFields) => {
      await updateProduct(data)
      queryClient.invalidateQueries({ queryKey: ['products', productId] })
      navigate(CABINET_PRODUCTS_ROUTE)
    },
    [navigate, updateProduct, queryClient],
  )

  // @ts-ignore
  const { customerAvatar, customerName, status } = product

  const handleApproveClick = useCallback(async () => {
    await handleSubmit(updateProduct)()
    await productVerificationMutation({ approval: [productId], disapproval: [] })
    queryClient.invalidateQueries({ queryKey: ['products'] })
    navigate(CABINET_PRODUCTS_ROUTE)
  }, [navigate, handleSubmit, updateProduct, productVerificationMutation, queryClient, productId])

  const handleDisapproveClick = useCallback(async () => {
    await handleSubmit(updateProduct)()
    await productVerificationMutation({ approval: [], disapproval: [productId] })
    queryClient.invalidateQueries({ queryKey: ['products'] })
    navigate(CABINET_PRODUCTS_ROUTE)
  }, [navigate, handleSubmit, updateProduct, productVerificationMutation, queryClient, productId])

  const renderButtons = useCallback(() => {
    if (status === ProductStatusType.PENDING) {
      return (
        <>
          <CabinetActionButton kind="primary" type="button" disabled={isButtonDisabled} onClick={handleApproveClick}>
            Подтвердить
          </CabinetActionButton>
          <CabinetActionButton kind="ghost" type="button" disabled={isButtonDisabled} onClick={handleDisapproveClick}>
            Отклонить
          </CabinetActionButton>
        </>
      )
    }

    if (status === ProductStatusType.CONFIRMED) {
      return (
        <CabinetActionButton kind="ghost" type="button" disabled={isButtonDisabled} onClick={handleDisapproveClick}>
          Отклонить
        </CabinetActionButton>
      )
    }

    if (status === ProductStatusType.REJECTED) {
      return (
        <CabinetActionButton kind="ghost" type="button" disabled={isButtonDisabled} onClick={handleApproveClick}>
          Подтвердить
        </CabinetActionButton>
      )
    }
  }, [status, isButtonDisabled, handleApproveClick, handleDisapproveClick])

  return (
    <FormProvider {...methods}>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <BaseProductForm isDisabled={isLoading}>
          <Field id="customer" label="Селлер:" kind="cabinet">
            <Flex alignItems="center" gap={16}>
              <Avatar avatar={customerAvatar} firstName={customerName} />
              <span className={styles.CustomerName}>{customerName}</span>
            </Flex>
          </Field>
        </BaseProductForm>
        <Flex className={styles.Buttons} flexDirection="column" gap={8}>
          <CabinetActionButton disabled={isButtonDisabled}>Сохранить</CabinetActionButton>
          {renderButtons()}
        </Flex>
      </form>
    </FormProvider>
  )
}
