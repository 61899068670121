import React, { FC, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { AuthLayout } from 'src/widgets/AuthLayout'
import { AuthBlock } from 'src/widgets/AuthBlock'
import { AuthButton } from 'src/shared/ui/AuthButton'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { phoneRules } from 'src/shared/lib/phoneRules'
import { firstNameRules } from 'src/shared/lib/firstNameRules'
import { passwordRules } from 'src/shared/lib/passwordRules'
import { getPasswordAgainRules } from 'src/shared/lib/getPasswordAgainRules'
import { useRegistrateMutation } from 'src/shared/model/useRegistrateMutation'
import { SignUpDto } from 'src/shared/api'
import { LOGIN_ROUTE } from 'src/shared/config/consts'
import { useScrollToTop } from 'src/shared/lib/useScrollToTop'

import styles from './CustomerRegistration.module.scss'

interface CustomerRegistrationFormFields {
  phone: string
  password: string
  passwordAgain: string
  firstName: string
  lastName: string
  inn?: string
}

export const CustomerRegistration: FC = () => {
  const navigate = useNavigate()
  useScrollToTop()

  const methods = useForm<CustomerRegistrationFormFields>()
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  const registrateMutation = useRegistrateMutation()
  const isLoading = registrateMutation.isPending

  const onSubmit = useCallback(
    (data: CustomerRegistrationFormFields) => {
      const signUpDto: SignUpDto = {
        phone: `+7${data.phone}`,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        role: 'CUSTOMER',
        customer: {
          inn: data.inn || '',
        },
      }

      registrateMutation.mutate(signUpDto, {
        onSuccess: () => navigate(LOGIN_ROUTE),
        onError: (error) => setError('phone', error.error),
      })
    },
    [registrateMutation, setError],
  )

  const passwordAgainRules = useMemo(() => getPasswordAgainRules(values.password), [values.password])

  const isAuthButtonDisabled = useMemo(
    () =>
      !(
        Boolean(values.phone) &&
        Boolean(values.firstName) &&
        Boolean(values.password) &&
        Boolean(values.passwordAgain)
      ) || isLoading,
    [values, isLoading],
  )

  return (
    <AuthLayout>
      <AuthBlock title="Регистрация селера">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.InputsContainer}>
              <PhoneInputFormField
                id="phone"
                name="phone"
                label="Номер телефона"
                placeholder="Номер телефона"
                rules={phoneRules}
                disabled={isLoading}
                isRequired
              />
              <TextInputFormField
                id="firstName"
                name="firstName"
                label="Ваше имя"
                placeholder="Имя"
                rules={firstNameRules}
                disabled={isLoading}
                isRequired
              />
              <TextInputFormField
                id="lastName"
                name="lastName"
                label="Фамилия"
                placeholder="Фамилия"
                disabled={isLoading}
              />
              <NumericInputFormField id="inn" name="inn" label="ИНН" placeholder="ИНН" disabled={isLoading} />
              <TextInputFormField
                id="password"
                name="password"
                label="Придумайте пароль"
                placeholder="Пароль"
                type="password"
                rules={passwordRules}
                disabled={isLoading}
                isRequired
              />
              <TextInputFormField
                id="passwordAgain"
                name="passwordAgain"
                label="Повторите пароль"
                placeholder="Пароль"
                type="password"
                rules={passwordAgainRules}
                disabled={isLoading}
                isRequired
              />
            </div>
            <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
              Зарегестрироваться как селер
            </AuthButton>
          </form>
        </FormProvider>
      </AuthBlock>
    </AuthLayout>
  )
}
