import React, { FC } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Select, SelectPropsType } from '../Select'

export type SelectFieldPropsType = Omit<FieldPropsType, 'children'> & SelectPropsType

export const SelectField: FC<SelectFieldPropsType> = ({
  className,
  label,
  id,
  error,
  isRequired,
  withHiddenLabel,
  ...rest
}) => {
  return (
    <Field
      className={className}
      kind="cabinet"
      label={label}
      id={id}
      error={error}
      isRequired={isRequired}
      withHiddenLabel={withHiddenLabel}
    >
      <Select id={id} isInvalid={Boolean(error)} {...rest} />
    </Field>
  )
}
