import React, { FC } from 'react'
import { Accordion } from 'src/shared/ui/Accordion'
import { QUESTIONS } from '../../config/consts'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CabinetFAQ.module.scss'

export const CabinetFAQ: FC = () => {
  return (
    <Flex className={styles.CabinetFAQ} flexDirection="column" isFluid>
      <h2 className={styles.Title}>Частые вопросы</h2>
      <Accordion kind="cabinet" data={QUESTIONS} />
    </Flex>
  )
}
