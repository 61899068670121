export const BASE_ROUTE = '/'
export const BLOGGER_LANDING_ROUTE = '/blogger'
export const BLOGGER_REGISTRATION_ROUTE = '/blogger/registartion'
export const CUSTOMER_LANDING_ROUTE = '/customer'
export const CUSTOMER_REGISTRATION_ROUTE = '/customer/registartion'
export const LOGIN_ROUTE = '/login'
export const CABINET_ROUTE = '/cabinet'
export const CABINET_BLOGGERS_INFO_ROUTE = `${CABINET_ROUTE}/bloggers-info`
export const CABINET_ORDER_QUIDE_ROUTE = `${CABINET_ROUTE}/order-guide`
export const CABINET_PRODUCTS_ROUTE = `${CABINET_ROUTE}/products`
export const CABINET_PRODUCTS_NEW_ROUTE = `${CABINET_PRODUCTS_ROUTE}/new`
export const CABINET_PRODUCT_ROUTE = `${CABINET_PRODUCTS_ROUTE}/:productId`
export const CABINET_TASKS_ROUTE = `${CABINET_ROUTE}/tasks`
export const CABINET_TASKS_NEW_ROUTE = `${CABINET_TASKS_ROUTE}/new`
export const CABINET_TASK_ROUTE = `${CABINET_TASKS_ROUTE}/:taskId`
export const CABINET_TASK_RESPONSE_ROUTE = `${CABINET_TASKS_ROUTE}/:taskId/responses/:responseId`
export const CABINET_PROFILE_ROUTE = `${CABINET_ROUTE}/profile`
export const CABINET_USERS_ROUTE = `${CABINET_ROUTE}/users`
export const CABINET_USER_ROUTE = `${CABINET_ROUTE}/users/:userId`
export const CABINET_TOPICS_ROUTE = `${CABINET_ROUTE}/topics`
export const CABINET_MARKETPLACES_ROUTE = `${CABINET_ROUTE}/marketplaces`
export const CABINET_NOTIFICATIONS = `${CABINET_ROUTE}/notifications`
export const CABINET_SETTINGS = `${CABINET_ROUTE}/settings`
