export const COMMENTS = [
  [
    {
      text: 'Спасибо, все отлично 🫶 оплата приходит сразу же. Классная платформа, хороший выбор товаров, все очень удобно и доступно, надеюсь на дальнейшее сотрудничество 🥰',
      name: 'Анастасия Ефремова',
    },
    {
      text: (
        <p>
          Здравствуйте! <br />
          Безумно довольна платформой, в боте все максимально понятно и пошагово, брала уже несколько интеграций и
          каждый раз оплата приходила в течение нескольких часов, что очень удобно, так как товары приходят обычно на
          следующий день. Товары классные и качественные 😍Если нужна какая-то помощь — пишу в поддержку и в течении
          нескольких минут мне помогают и отвечают на все вопросы, которые меня интересуют, что тоже очень важно 🥺🫶
        </p>
      ),
      name: 'Дарина Белоусова',
    },
    {
      text: (
        <p>
          Я блогер со стажем 😃 и для меня была огромная находка найти данную платформу! <br />
          Это очень удобно, каждое действие расписано по порядку, все внятно и чётко, огромный ассортимент разных
          товаров, одежда, косметика, детские игрушки, и даже настольные игры. <br />
          Всем рекомендую!
        </p>
      ),
      name: 'Ирина Светлогорова',
    },
    {
      text: (
        <p>
          Как блогер, я постоянно ищу качественные и интересные товары, которые можно рекомендовать своей аудитории.
          Когда одна моя знакомая порекомендовала мне Marketiq, я была в шоке от качества товаров и работы данного
          сервиса. Очень удобно, есть все необходимое. <br />
          Служба поддержки 24/7 помогают в любом вопросе.
        </p>
      ),
      name: 'Марина Летнева',
    },
  ],
  [
    {
      text: 'Ваша платформа одна из лучших 😻 благодарю, что учавствую в ваших проектах 😌 очень простое пользование и оплата приходит быстро 😊💫',
      name: 'Татьяна Ветрова',
    },
    {
      text: (
        <p>
          Здравствуйте&#41; <br />
          Учавствую в бартере около месяца, на разных заказах и захотелось отметить, что у вас очень удобная платформа,
          от оформления заказа до оперативной обратной связи. 😊 <br />
          Есть с чем сравнить и я приятно удивлена такому открытому и честному подходу&#41;
          <br />
          Большое спасибо за такое сотрудничество!
        </p>
      ),
      name: 'Анна Михайлова',
    },
    {
      text: (
        <p>
          Все просто, от выбора товаров до опубликованной рекламы! <br /> Не надо тратить время в поисках заказчиков!
          <br />
          Тут просто выбираешь товар и работаешь. Я в восторге от наполнения данного сервиса! Продумано до мелочей.
        </p>
      ),
      name: 'Виктория Лаврова',
    },
    {
      text: (
        <p>
          Радумает что я сама могу выбирать товары, которые считаю нужными и подходящими для своего блога! Это не портит
          картинку каждого блогера, что очень важно! <br />
          Все условия прозрачны и понятны, если есть вопросы — их всегда можно задать службе поддержки.
        </p>
      ),
      name: 'Лариса Ясинская',
    },
  ],
  [
    {
      text: 'Платформа очень классная и очень удобная, оплата приходит бытро, мне прям нравится работать ❤️',
      name: 'Ева Каменская',
    },
    {
      text: (
        <p>
          Да, мне все нравится 👍 с оплатой не опаздываете, и товары все качественные 😍 <br />
          Спасибо большое 🙏
        </p>
      ),
      name: 'Ксения Лисина',
    },
    {
      text: (
        <p>
          Добрый день! Хочу поблагодарить вас за создание такой замечательной платформы как ваша!!! 🥰🥰🥰 <br />
          Это очень удобно, большое разнообразие товаров, удобное оформление, оплаты приходят все время вовремя! <br />
          Учавствовала во многих бартерах, но у вас мне нравится больше всего 🫶 <br />
          <br />
          Отдельная благодарность службе поддержки, ответы всгда через секунду, помощь в любом вопросеи в любое время!
          <br />
          Очень советую всем начинающим и продвинутым блогерам 🩷
        </p>
      ),
      name: 'Милена Хачатрян',
    },
    {
      text: (
        <p>
          Хочу выразить огромное спасибо создателям данной платформы. Учавствую в роли блогера, работаю на данной
          платформе около трех месяцев. Очень удобно что можешь сам выбирать товар, особенно когда есть техническое
          задание! <br />
          Удобно, что приходят разные напоминания, потому что в голове удержать всю информацию невозможно, спасибо вам
          большое! 😍
        </p>
      ),
      name: 'Диана Беловодова',
    },
  ],
]
