import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { RoleType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { CustomerProfileForm } from 'src/pages/CustomerProfileForm'
import { useUserInfo } from 'src/shared/model/useUser'
import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { BloggerProfile } from '../BloggerProfile/BloggerProfile'

import styles from './CabinetProfile.module.scss'

export const CabinetProfile: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const { user } = useUserInfo()
  const profile = useCustomerProfile()

  const isBloggerProfile = user?.role === RoleType.BLOGER
  const isCustomerProfile = user?.role === RoleType.CUSTOMER

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Личный кабинет</h1>
      </Flex>

      {isBloggerProfile && <BloggerProfile />}
      {isCustomerProfile && <CustomerProfileForm profile={profile} key={`profile-${profile?.id}`} />}
    </CabinetPageLayout>
  )
}
