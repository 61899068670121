import React, { FC, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Flex } from 'src/shared/ui/Flex'

import { useUserInfo } from 'src/shared/model/useUser'

import { phoneRules } from 'src/shared/lib/phoneRules'
import { firstNameRules } from 'src/shared/lib/firstNameRules'

import styles from './BloggerProfileForm.module.scss'

interface BloggerProfileFormFields {
  avatar: File | string
  name: string
  lastName: string
  phone: string
}

interface BloggerProfileFormProps {
  profile?: any
}

export const BloggerProfileForm: FC<BloggerProfileFormProps> = ({ profile }) => {
  const { logout } = useUserInfo()

  const methods = useForm<BloggerProfileFormFields>({
    defaultValues: {
      avatar: profile?.avatar || '',
      name: profile?.firstName || '',
      lastName: profile?.lastName || '',
      phone: profile?.phone.slice(2) || '',
    },
  })
  const { handleSubmit } = methods

  const onSubmit = useCallback(async () => {
    try {
      // TODO - Не хватает изменения профиля блоггера
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.Form}>
        <div className={styles.FormInputs}>
          {/* <ProfileAvatarForm name="avatar" firstName={profile?.firstName} /> */}
          <TextInputFormField id="name" name="name" label="Имя" rules={firstNameRules} kind="cabinet" disabled />
          <TextInputFormField id="lastName" name="lastName" label="Фамилия" kind="cabinet" disabled />
          <PhoneInputFormField
            id="phone"
            name="phone"
            label="Номер телефона"
            placeholder="Номер телефона"
            rules={phoneRules}
            kind="cabinet"
            disabled
          />
        </div>
        <Flex alignItems="center" justifyContent="space-between" className={styles.Footer}>
          <CabinetActionButton kind="ghost" className={styles.ExitButton} onClick={logout} type="button">
            Выйти из профиля
          </CabinetActionButton>
          {/* <CabinetButton className={styles.CabinetButton} disabled={true}>
            Сохранить
          </CabinetButton> */}
        </Flex>
      </form>
    </FormProvider>
  )
}
