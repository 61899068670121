import { Api } from './AdminAPI'
export { StorageAPI } from './StorageAPI'
export * from './AdminAPI'

const customFetch: typeof fetch = async (url, options = {}) => {
  const response = await fetch(url, options)

  const newAccessToken = response.headers.get('authorization')

  if (newAccessToken) {
    localStorage.setItem('accessToken', newAccessToken)
  }

  return response
}

export const AdminAPI = new Api({
  baseUrl: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_PROXY_URL : '',
  securityWorker: () => ({
    headers: {
      Authorization: localStorage.getItem('accessToken') as string,
      Client: localStorage.getItem('fpHash') as string,
    },
  }),
  customFetch,
})
