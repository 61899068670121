import React, { FC, useMemo, useState } from 'react'
import { BloggerStatusType } from 'src/shared/config/types'
import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'
import { BloggerVerification } from '../BloggerVerification/BloggerVerification'
import { BloggerProfileForm } from 'src/pages/BloggerProfileForm'
import { Tabs } from 'src/shared/ui/Tabs'

import styles from './BloggerProfile.module.scss'

enum TabType {
  PERSONAL_INFO_TAB_ID = 'personal',
  VERIFICATION_TAB_ID = 'verification',
}

export const BloggerProfile: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(TabType.PERSONAL_INFO_TAB_ID)
  const profile = useBloggerProfile()

  const tabs = useMemo(
    () => [
      {
        tabId: TabType.PERSONAL_INFO_TAB_ID,
        title: 'Личная информация',
      },
      {
        tabId: TabType.VERIFICATION_TAB_ID,
        title: 'Верификация аккаунта',
      },
    ],
    [],
  )

  const isBloggerActivated = profile?.status === BloggerStatusType.NOT_ACTIVATED && !profile?.isFullInfo

  return (
    <div>
      {isBloggerActivated ? (
        <>
          <Tabs className={styles.Tabs} tabs={tabs} activeTabId={activeTabId} onTabClick={setActiveTabId} />
          {activeTabId === TabType.PERSONAL_INFO_TAB_ID && <BloggerProfileForm profile={profile} />}
          {activeTabId === TabType.VERIFICATION_TAB_ID && <BloggerVerification />}
        </>
      ) : (
        <BloggerProfileForm profile={profile} key={`profile-${profile?.id}`} />
      )}
    </div>
  )
}
