import React, { FC } from 'react'
import { Outlet } from 'react-router'
import { AuthPermission } from 'src/widgets/AuthPermission'
import { Header } from '../Header/Header'
import { useScrollToTop } from 'src/shared/lib/useScrollToTop'

import styles from './CabinetLayout.module.scss'

export const CabinetLayout: FC = () => {
  useScrollToTop()

  return (
    <AuthPermission>
      <div className={styles.CabinetLayout}>
        <Header />
        <div className={styles.Container}>
          <Outlet />
        </div>
      </div>
    </AuthPermission>
  )
}
