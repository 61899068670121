import React, { FC } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { LINKS } from '../../config/consts'

import styles from './CabinetLinks.module.scss'

export const CabinetLinks: FC = () => {
  return (
    <Flex className={styles.CabinetLinks} flexDirection="column" isFluid>
      <h2 className={styles.Title}>Ссылки</h2>
      <Flex flexDirection="column" gap={20}>
        {LINKS.map((link, index) => (
          <article key={index} className={styles.Link}>
            <div className={styles.Link__info}>
              <Flex flexDirection="column" gap={8}>
                <strong className={styles.Link__title}>{link.title}</strong>
                <p className={styles.Link__desc}>{link.desc}</p>
              </Flex>
              {!link.href ? (
                <span className={cn(styles.Link__button, styles.Link__button_disabled)} aria-disabled="true">
                  Подробнее
                </span>
              ) : (
                <Link to={link.href} className={styles.Link__button}>
                  Подробнее
                </Link>
              )}
            </div>
            <div className={styles.Link__img}>
              <img src={link.img} alt="" />
            </div>
          </article>
        ))}
      </Flex>
    </Flex>
  )
}
