import React, { ReactElement } from 'react'
import { ProfileAvatar, ProfileAvatarProps } from '../ProfileAvatar/ProfileAvatar'
import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'
import { ReactHookFormFieldType } from 'src/shared/config/types'
import { FieldValues } from 'react-hook-form'

type ProfileAvatarFormPropsType<TFieldValues extends FieldValues = FieldValues> = Omit<
  ReactHookFormFieldType<TFieldValues, ProfileAvatarProps>,
  'file'
>

export const ProfileAvatarForm = <TFieldValues extends FieldValues = FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  ...props
}: ProfileAvatarFormPropsType<TFieldValues>): ReactElement<ProfileAvatarFormPropsType<TFieldValues>> => {
  const { field } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return <ProfileAvatar {...field} file={field.value} {...props} />
}
