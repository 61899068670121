import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CABINET_PRODUCTS_NEW_ROUTE, CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { AdminAPI, ProductResponseDto } from 'src/shared/api'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { Product } from 'src/shared/ui/Product'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CabinetProducts.module.scss'

export const CabinetProducts: FC = () => {
  const navigate = useNavigate()
  const handleAddProductClick = useCallback(() => navigate(CABINET_PRODUCTS_NEW_ROUTE), [])

  const { data: productsData } = useQuery({
    queryKey: ['products'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.productsControllerFindAll({ page:  1, take: 200 }),
  })

  // TODO Разработать с типом ответа
  // @ts-ignore
  const products = useMemo<ProductResponseDto[]>(() => productsData?.data?.products ?? [], [productsData])

  return (
    <CabinetPageLayout>
      <Flex className={styles.Header} justifyContent="space-between" alignItems="center">
        <h1 className={styles.Title}>Мои товары</h1>
        <CabinetGhostButton onClick={handleAddProductClick} icon="plus">Добавить товар</CabinetGhostButton>
      </Flex>
      <Flex className={styles.Products} flexWrap="wrap">
        {products.map(({ id, photoUrl, title, price }) => (
          <Product
            key={id}
            photoUrl={photoUrl}
            title={title}
            price={price}
            onClick={() => navigate(`${CABINET_PRODUCTS_ROUTE}/${id}`)}
          />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
