import React, { FC, ReactNode, useMemo } from 'react'
import { Navigate } from 'react-router'
import { LOGIN_ROUTE } from 'src/shared/config/consts'
import { useUserInfo } from 'src/shared/model/useUser'

interface AuthPermissionPropsType {
  children: ReactNode
}

export const AuthPermission: FC<AuthPermissionPropsType> = ({ children }) => {
  const { accessToken, user } = useUserInfo()

  const hasPermission = useMemo(() => {
    if (!accessToken || !user) {
      return false
    }

    return true
  }, [accessToken, user])

  if (!hasPermission) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    localStorage.removeItem('fpHash')

    return <Navigate to={LOGIN_ROUTE} />
  }

  return <>{children}</>
}
