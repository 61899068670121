import React, { FC } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetFAQ } from '../CabinetFAQ/CabinetFAQ'
import { CabinetLinks } from '../CabinetLinks/CabinetLinks'
import { Flex } from 'src/shared/ui/Flex'

import styles from './Cabinet.module.scss'

export const Cabinet: FC = () => {
  return (
    <CabinetPageLayout>
      <Flex className={styles.Container} gap={32}>
        <CabinetFAQ />
        <CabinetLinks />
      </Flex>
    </CabinetPageLayout>
  )
}
