import React, { ForwardedRef, forwardRef } from 'react'
import cn from 'classnames'
import { NumericInput, NumericInputPropsType } from '../NumericInput'

import styles from './PhoneInput.module.scss'

export type PhoneInputPropsType = NumericInputPropsType

const PhoneInputWithRef = (
  { className, kind = 'landing', isInvalid, ...rest }: PhoneInputPropsType,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <div
      className={cn(
        styles.PhoneInput,
        isInvalid && styles.isInvalid,
        kind === 'landing' && styles.PhoneInput__landing,
        (kind === 'cabinet' || kind === 'smallCabinet') && styles.PhoneInput__cabinet,
        kind === 'smallCabinet' && styles.PhoneInput__cabinet_small,
        className,
      )}
    >
      <div className={styles.Code}>+7</div>
      <NumericInput className={styles.NumericInput} kind={kind} isInvalid={isInvalid} {...rest} />
    </div>
  )
}

export const PhoneInput = forwardRef(PhoneInputWithRef)
