import React, { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { AdminAPI, CommonMessageResponse, CreateProductDto, HttpResponse } from 'src/shared/api'
import { useUpload } from 'src/shared/model/useUpload'
import { ProductForm as BaseProductForm, ProductFormFields, useProductForm } from 'src/widgets/ProductForm'
import { FormProvider } from 'react-hook-form'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'

import styles from './CabinetProductsNew.module.scss'

export const CabinetProductsNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_PRODUCTS_ROUTE), [navigate])

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const { methods, isInputsInvalid } = useProductForm()
  const { handleSubmit } = methods

  const createProductMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateProductDto
  >({
    mutationFn: AdminAPI.api.productsControllerCreate,
  })
  const isLoading = createProductMutation.isPending || isUploading

  const onSubmit = useCallback(
    async (data: ProductFormFields) => {
      try {
        let photoUrl: string
        if (typeof data.photo === 'string') {
          photoUrl = data.photo
        } else {
          setIsUploading(true)
          photoUrl = await upload(data.photo)
          setIsUploading(false)
        }

        const createProductDto: CreateProductDto = {
          topics: data.topics.filter((item) => item.topicId).map((item) => ({ topicId: Number(item.topicId) })),
          marketplaceUrl: data.marketplaceUrl,
          marketplaceId: Number(data.marketplaceId),
          title: data.title,
          article: data.article,
          price: Number(data.price),
          photoUrl,
        }

        createProductMutation.mutate(createProductDto, {
          onSuccess: () => {
            navigate(CABINET_PRODUCTS_ROUTE, { replace: true })
          },
          onError: (error) => {
            console.error(error.error)
          },
        })
      } catch (e) {
        console.error(e)
        setIsUploading(false)
      }
    },
    [createProductMutation, navigate],
  )

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Добавление товара</h1>
      </Flex>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <BaseProductForm isDisabled={isLoading} />
          <CabinetButton className={styles.CabinetButton} disabled={isInputsInvalid}>
            Добавить товар
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
