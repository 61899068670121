import React, {
  ChangeEventHandler,
  DragEventHandler,
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import { Icon } from '../Icon'

import styles from './Uploader.module.scss'

export interface UploaderPropsType {
  className?: string
  type: 'image' | 'video'
  file?: File | string
  onChange(file: File): void
  placeholder?: string
  isDisabled?: boolean
}

export const Uploader: FC<UploaderPropsType> = ({ className, type, file, onChange, placeholder, isDisabled }) => {
  const [isActive, setIsActive] = useState(false)
  const [imagePreviewSrc, setImagePreviewSrc] = useState<string | ArrayBuffer | null | undefined>()
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (file && type === 'image') {
      if (typeof file === 'string') {
        setImagePreviewSrc(file)
      } else {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = (fileReaderEvent: ProgressEvent<FileReader>) => {
          setImagePreviewSrc(fileReaderEvent?.target?.result)
        }
      }
    }
  }, [type, file])

  const fileHandler = useCallback(
    (fileList: FileList) => {
      const image = fileList[0]

      onChange(image)
    },
    [onChange],
  )

  const handleClick = useCallback<MouseEventHandler>(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [])

  const handleDragLeave = useCallback(() => setIsActive(false), [])

  const handleDragOver = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
    event.preventDefault()
    setIsActive(true)
  }, [])

  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault()

      if (event.type === 'drop' && event.dataTransfer.files) {
        setIsActive(false)
        fileHandler(event.dataTransfer.files)
      }
    },
    [fileHandler],
  )

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.target.files) {
        fileHandler(event.target.files)
      }

      event.target.value = ''
    },
    [fileHandler],
  )

  const renderPreview = useCallback(() => {
    if (type === 'image') {
      return <img className={styles.ImagePreview} src={imagePreviewSrc as string} />
    }

    if (type === 'video' && typeof file !== 'string') {
      return <div className={styles.Placeholder}>{file?.name}</div>
    }
  }, [type, file, imagePreviewSrc])

  return (
    <div
      className={cn(styles.Uploader, isActive && styles.isActive, isDisabled && styles.isDisabled, className)}
      onClick={handleClick}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        accept={`${type}/*`}
        className={styles.HiddenInput}
        ref={fileInputRef}
        type="file"
        onChange={handleInputChange}
        disabled={isDisabled}
      />
      {file ? (
        renderPreview()
      ) : (
        <div className={styles.Placeholder}>
          <Icon name="upload" />
          {placeholder ?? (type === 'image' ? 'Загрузить фото' : 'Загрузить видео')}
        </div>
      )}
    </div>
  )
}
