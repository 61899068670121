import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { PhoneInputFormField } from 'src/shared/ui/PhoneInputFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { AuthButton } from 'src/shared/ui/AuthButton'
import { AuthBlock } from 'src/widgets/AuthBlock'
import { AuthLayout } from 'src/widgets/AuthLayout'
import { phoneRules } from 'src/shared/lib/phoneRules'
import { passwordRules } from 'src/shared/lib/passwordRules'
import { AdminAPI, CommonMessageResponse, HttpResponse, SignInDto, SignInResponseDto } from 'src/shared/api'
import { CABINET_ROUTE } from 'src/shared/config/consts'
import { useScrollToTop } from 'src/shared/lib/useScrollToTop'

import styles from './Login.module.scss'

interface LoginFormFields {
  phone: string
  password: string
}

export const Login: FC = () => {
  const [fpHash, setFpHash] = useState('')
  const navigate = useNavigate()
  useScrollToTop()

  const methods = useForm<LoginFormFields>({
    defaultValues: {
      phone: '',
      password: '',
    },
  })
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()

      setFpHash(visitorId)
    }

    setFp()
  }, [])

  const loginMutation = useMutation<
    HttpResponse<SignInResponseDto, CommonMessageResponse>,
    HttpResponse<SignInResponseDto, CommonMessageResponse>,
    SignInDto
  >({
    mutationFn: AdminAPI.api.authControllerSignInDto,
  })
  const isLoading = loginMutation.isPending

  const onSubmit = useCallback(
    (data: LoginFormFields) => {
      const signInDto: SignInDto = {
        phone: `+7${data.phone}`,
        password: data.password,
        client: fpHash,
      }

      loginMutation.mutate(signInDto, {
        onSuccess: ({ data }) => {
          localStorage.setItem('accessToken', data.accessToken)
          localStorage.setItem('user', JSON.stringify(data.user))
          localStorage.setItem('fpHash', fpHash)

          navigate(CABINET_ROUTE)
        },
        onError: (error) => setError('phone', error.error),
      })
    },
    [fpHash, loginMutation],
  )

  const isAuthButtonDisabled = useMemo(
    () => !(Boolean(values.phone) && Boolean(values.password)) || isLoading,
    [values],
  )

  return (
    <AuthLayout>
      <AuthBlock title="Вход" className={styles.AuthBlock}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.InputsContainer}>
              <PhoneInputFormField
                id="phone"
                name="phone"
                label="Номер телефона"
                placeholder="Номер телефона"
                rules={phoneRules}
                disabled={isLoading}
              />
              <TextInputFormField
                id="password"
                name="password"
                label="Пароль"
                placeholder="Пароль"
                type="password"
                rules={passwordRules}
                disabled={isLoading}
              />
            </div>
            <AuthButton className={styles.AuthButton} disabled={isAuthButtonDisabled}>
              Войти
            </AuthButton>
          </form>
        </FormProvider>
      </AuthBlock>
    </AuthLayout>
  )
}
