import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEvent,
  FocusEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useState,
} from 'react'
import { useUpdateEffect } from 'react-use'

import { Input, InputPropsType } from '../Input'

import styles from './NumericInput.module.scss'
import cn from 'classnames'

const normalizeIntegerString = (value: string): string => value.replace(/[^\d-]/g, '').replace(/(?!^)-(?=.)*/g, '')

const normalizeNumberString = (value?: string): string => {
  const numberValue = value ? parseFloat(value) : 0

  if (isNaN(numberValue)) {
    return ''
  }

  return numberValue.toString()
}

export type NumericInputOnChangeHandlerType = (
  event:
    | ChangeEvent<HTMLInputElement>
    | MouseEvent<HTMLButtonElement>
    | FocusEvent<HTMLInputElement>
    | KeyboardEvent<HTMLInputElement>,
  value: string,
) => void

export interface NumericInputPropsType extends Omit<InputPropsType, 'onChange' | 'postfix'> {
  onChange?: NumericInputOnChangeHandlerType
  unit?: string
}

export const NumericInput: FC<NumericInputPropsType> = ({ value, onChange, onBlur, onKeyDown, unit, ...props }) => {
  const [stateValue, setStateValue] = useState<string>(
    value !== undefined && value !== '' ? normalizeNumberString(value.toString()) : '',
  )

  useUpdateEffect(() => {
    if (value !== String(parseFloat(stateValue))) {
      setStateValue(value !== undefined && value !== '' ? normalizeNumberString(value.toString()) : '')
    }
  }, [value])

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      if (stateValue !== '') {
        const fieldValue = normalizeNumberString(stateValue)

        setStateValue(fieldValue)
        onChange?.(e, fieldValue)
      }
      onBlur?.(e)
    },
    [onBlur, onChange, stateValue],
  )

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === 'Enter') {
        if (stateValue !== '') {
          const fieldValue = normalizeNumberString(stateValue)

          setStateValue(fieldValue)
          onChange?.(e, fieldValue)
        }
      }
      onKeyDown?.(e)
    },
    [onKeyDown, onChange, stateValue],
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const normalInputValue = normalizeIntegerString(e.target.value)

      setStateValue(normalInputValue)

      if (normalInputValue === '' || normalInputValue === String(parseFloat(normalInputValue))) {
        onChange?.(e, normalInputValue)
      }
    },
    [onChange],
  )

  return (
    <Input
      value={stateValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      postfix={unit && <div className={styles.Unit}>{unit}</div>}
      className={cn(unit && styles.InputWithUnit)}
      {...props}
    />
  )
}
